import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ProfileEdiSvg from "../../../../../assets/images/user-profile-edit.svg";
import CheckedSvg from "../../../../../assets/images/verified-circle.svg";
import EditProfile from "../edit-profile";
import useStyles from "./styles";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";

const ProfileDetails = ({userData, setUserData}) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const {firstName, lastName, email=userDetails.email, phoneNo, profilePic} = userData
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <div className={classes.profileMainContainer}>
      <div className={classes.profileHeaderContainer}>
        
        {isMobile ? (
          <div
            className={classes.mobileLoginDetailsTextAndEditProfileBtnContainer}
          >
            <p className={classes.loginDetailsText}>
              {t("myProfile.loginDetails")}
            </p>
            <div
              className={classes.editBoxOuterContainer}
              onClick={() => setOpenEditProfileModal(true)}
            >
              <div className={classes.editTextInnerContainer}>
                <ReactSVG src={ProfileEdiSvg} style={{ marginTop: "2px" }} />
                <Typography className={classes.editText}>
                  {t("myProfile.edit")}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={classes.loginDetailsText}>
              {t("myProfile.loginDetails")}
            </p>
            <p className={classes.manageLoginDetailsText}>
              {t("myProfile.manageContactDetails")}{" "}
            </p>
          </div>
        )}
        {isMobile ? (
          <p className={classes.manageLoginDetailsText}>
            {t("myProfile.manageContactDetails")}
          </p>
        ) : (
          <div
            className={classes.editBoxOuterContainer}
            onClick={() => setOpenEditProfileModal(true)}
          >
            <div className={classes.editTextInnerContainer}>
              <ReactSVG src={ProfileEdiSvg} style={{ marginTop: "2px" }} />
              <Typography className={classes.editText}>
                {t("myProfile.edit")}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className={classes.profileDetailsContainer}>
        <div className={classes.firstNameContainer}>
          <p className={classes.firstNameLabel}>{t("myProfile.firstName")}</p>
          <p className={classes.firstName}>{firstName}</p>
        </div>
        <div className={classes.lastNameContainer}>
          <p className={classes.lastNameLable}>{t("myProfile.lastName")}</p>
          <p className={classes.lastName}>{lastName}</p>
        </div>
        <div className={classes.mobileNumberContainer}>
          <p className={classes.mobileNumberLable}>
            {t("myProfile.mobileNumber")}
          </p>
          {isMobile ? (
            <div className={classes.mobileNumberAndIcon}>
              <p className={classes.mobileNumber}>{phoneNo}</p>
              <ReactSVG src={CheckedSvg} />
            </div>
          ) : (
            ""
          )}
          {!isMobile && <p className={classes.mobileNumber}>{phoneNo}</p>}
          {!isMobile && <ReactSVG src={CheckedSvg} />}
        </div>
        <div className={classes.emailIdContainer}>
          <p className={classes.emailIdLable}>{t("myProfile.emailId")}</p>
          {isMobile ? (
            <div className={classes.emailIdAndIcon}>
              <p className={classes.emailId}>{email}</p>
              <ReactSVG src={CheckedSvg} />
            </div>
          ) : (
            ""
          )}
          {!isMobile && <p className={classes.emailId}>{email}</p>}
          {!isMobile && <ReactSVG src={CheckedSvg} />}
        </div>
      </div>
      {openEditProfileModal && (
        <EditProfile
          openEditProfileModal={openEditProfileModal}
          setOpenEditProfileModal={setOpenEditProfileModal}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNo={phoneNo}
          profilePic={profilePic}
          setUserData={setUserData}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
