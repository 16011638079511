import React from "react";
import PlaceholderImage from "../../../../../../assets/images/logo.png";
import rightArrowIcon from "../../../../../../assets/images/right-arrow-black.svg";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./styles.css";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const currency = ["SAR"];

const CancellationCharges = ({ flight }) => {
  if (Object.keys(flight).length === 0) return <></>;
  const isMobile = useIsMobile();
  const styles = useStyles();
  const { t } = useTranslation();
  const { Segments, MiniFareRules } = flight;
  const {
    Airline: { AirlineCode },
    Origin: {
      // Airport: { CityName: origin, CityCode: originCitycode },
      CityName: origin,
      CityCode: originCityCode,
    },
  } = Segments[0][0];
  const {
    Destination: {
      // Airport: { CityName: destination, CityCode: destCityCode },
      CityName: destination,
      CityCode: destCityCode,
    },
  } = Segments[0][Segments[0]?.length - 1];

  return (
    <>
      <div className="cancellationCharges-parent-div">
        <p className="from-date-departure-text2">
          {t("fairRulePopUp.fromDateOfDeparture")}
        </p>
        {/* <div className="route-parent-div">
          <div style={{ display: "flex" }}>
            <img
              className="airline-image"
              src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
              onError={(e) => {
                e.target.src = PlaceholderImage;
              }}
              style={{ height: "36px", width: "42px" }}
            ></img>
            <div style={{ display: "flex", marginTop: "4px", gap: "6px" }}>
              <div className="route-source-text">
                {isMobile ? originCitycode : origin}
              </div>
              <div>
                <img src={rightArrowIcon} alt="right arrow icon"></img>
              </div>
              <div className="route-destination-text">
                {isMobile ? destCityCode : destination}
              </div>
            </div>
          </div>
          <div className="from-date-departure-text">
            *From the Date of Departure
          </div>
        </div> */}
        <table className={styles.customTable}>
          <tbody>
            <tr>
              <td className={styles.customTableHeading}>
                <div>{t("fairRulePopUp.timeFrame")}</div>
                <div className={styles.customTableMetaHeading}>
                  ({t("fairRulePopUp.fromScheduledDeparture")})
                </div>
              </td>
              <td className={styles.customTableHeading}>
                <div>{t("fairRulePopUp.fareDifference")}</div>
                <div className={styles.customTableMetaHeading}>
                  ({t("fairRulePopUp.perPassenger")})
                </div>
              </td>
            </tr>
            {MiniFareRules &&
              MiniFareRules.length > 0 &&
              MiniFareRules.map((miniFareRule) => {
                return miniFareRule
                  .filter((farerule) => farerule.Type === "Cancellation")
                  .map((farerule, index) => {
                    const { From, To, Unit, Details, JourneyPoints } = farerule;
                    return (
                      <tr key={index}>
                        {From ? (
                          To && To.length > 0 ? (
                            <td className={styles.customTableColumn}>
                              {From} {Unit} {t("fairRulePopUp.to")} {To} {Unit}
                              *&nbsp;(
                              {JourneyPoints})
                            </td>
                          ) : (
                            <td className={styles.customTableColumn}>
                              {From} {Unit}*&nbsp;({JourneyPoints})
                            </td>
                          )
                        ) : (
                          <td className={styles.customTableColumn}>
                            {t("fairRulePopUp.fromDateOfDeparture")}
                          </td>
                        )}
                        {/* {currency.includes(Details.split(" ")[0]) ? ( */}
                        <td className={styles.customTableColumnPrice}>
                          {Details}
                          {/* + Fare difference */}
                        </td>
                        {/* ) : (
                        <td>-</td>
                      )} */}
                      </tr>
                    );
                  });
              })}
          </tbody>
        </table>
        <Typography
          sx={{
            color: "#525A6A",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginTop: "8px",
            textAlign: "justify",
          }}
        >
          {/* Please be informed that the airline fee provided is an approximate
          estimate and is subject to change. At Joyus, we strive to provide
          accurate information, but we cannot guarantee its absolute accuracy.
          Kindly note that all fees mentioned are applicable to each passenger. */}
          {t("fairRulePopUp.footerInformationalText1")}
        </Typography>
      </div>
    </>
  );
};

export default CancellationCharges;

CancellationCharges.propTypes = {
  flight: PropTypes.object,
};
