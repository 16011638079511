import axios from "axios";
import APIs from "../api";

export const searchFlightForOneWay = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  token = "",
  setRateOfExchange
) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchFlightForOneWayAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      config,
      setRateOfExchange
    );
  } else {
    return handleSearchFlightForOneWayNoAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      setRateOfExchange
    );
  }
};

const handleSearchFlightForOneWayAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  config,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlightAuth, flightData, config);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log("Search Flight Auth API Error ", error);
    return error?.response?.data;
  }
};

const handleSearchFlightForOneWayNoAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlight, flightData);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log("Search Flight No Auth API Error ", error);
    return error.response.data;
  }
};

export const searchFlightForDomesticRoundTrip = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setInboundFilters,
  token = ""
) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchFlightForDomesticRoundTripAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      setInboundFilters,
      config
    );
  } else {
    return handleSearchFlightForDomesticRoundTripNoAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      setInboundFilters
    );
  }
};

const handleSearchFlightForDomesticRoundTripAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setInboundFilters,
  config
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
        },
      },
    } = await axios.post(APIs.searchFlightAuth, flightData, config);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      const { outboundFilterResults, inboundFilterResults } = filterResponse;
      setOutboundFilters(outboundFilterResults);
      setInboundFilters(inboundFilterResults);
      return { results };
    }
  } catch (error) {
    console.log("Search Domestic Round Trip Flight Auth API Error ", error);
    return error.response.data;
  }
};

const handleSearchFlightForDomesticRoundTripNoAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setInboundFilters
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
        },
      },
    } = await axios.post(APIs.searchFlight, flightData);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      const { outboundFilterResults, inboundFilterResults } = filterResponse;
      setOutboundFilters(outboundFilterResults);
      setInboundFilters(inboundFilterResults);
      return { results };
    }
  } catch (error) {
    console.log("Search Domestic Round Trip Flight No Auth API Error ", error);
    return error.response.data;
  }
};

export const searchFlightForInternationalRoundTrip = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  token = "",
  setRateOfExchange
) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchFlightForInternationalRoundTripAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      config,
      setRateOfExchange
    );
  } else {
    return handleSearchFlightForInternationalRoundTripNoAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      setRateOfExchange
    );
  }
};

const handleSearchFlightForInternationalRoundTripAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  config,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          TraceId,
          results,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlightAuth, flightData, config);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log(
      "Search International Round Trip Flight Auth API Error ",
      error
    );
    return error?.response?.data;
  }
};

const handleSearchFlightForInternationalRoundTripNoAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          TraceId,
          results,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlight, flightData);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log(
      "Search International Round Trip Flight No Auth API Error ",
      error
    );
    return error.response.data;
  }
};

export const searchFlightForMultiCity = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  token = "",
  setRateOfExchange
) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchFlightForMultiCityAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      config,
      setRateOfExchange
    );
  } else {
    return handleSearchFlightForMultiCityNoAuth(
      flightData,
      clearSearchedflights,
      setOutboundFilters,
      setRateOfExchange
    );
  }
};

const handleSearchFlightForMultiCityAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  config,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlightAuth, flightData, config);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log("Search Multi City Flight Auth API Error ", error);
    return error.response.data;
  }
};

const handleSearchFlightForMultiCityNoAuth = async (
  flightData,
  clearSearchedflights,
  setOutboundFilters,
  setRateOfExchange
) => {
  try {
    const {
      data: {
        statusCode,
        data: {
          results,
          TraceId,
          statusCode: responseStatusCode,
          filterResponse,
          LocalCurrencyROE,
        },
      },
    } = await axios.post(APIs.searchFlight, flightData);
    window.localStorage.setItem("traceId", TraceId);
    if (
      responseStatusCode === 400 ||
      statusCode === 400 ||
      statusCode === 500
    ) {
      clearSearchedflights();
      return { results: [] };
    }
    if (statusCode === 200) {
      setOutboundFilters(filterResponse);
      setRateOfExchange(LocalCurrencyROE);
      return { results };
    }
  } catch (error) {
    console.log("Search Multi City Flight No Auth API Error ", error);
    return error.response.data;
  }
};

export const searchClubbedFlights = async ({
  segmentIndex,
  packages,
  setClubbedFiltersForSecondCity,
  setClubbedFiltersForThirdCity,
  token = "",
}) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchClubbedFlightsAuth(
      segmentIndex,
      packages,
      setClubbedFiltersForSecondCity,
      setClubbedFiltersForThirdCity,
      config
    );
  } else {
    return handleSearchClubbedFlightsNoAuth(
      segmentIndex,
      packages,
      setClubbedFiltersForSecondCity,
      setClubbedFiltersForThirdCity
    );
  }
};

const handleSearchClubbedFlightsAuth = async (
  segmentIndex,
  packages,
  setClubbedFiltersForSecondCity,
  setClubbedFiltersForThirdCity,
  config
) => {
  try {
    const {
      data: {
        data: { searchResponse, filterResponse },
      },
    } = await axios.post(
      APIs.getClubbedFlightsAuth,
      { segmentIndex, packages },
      config
    );

    if (segmentIndex == 1) {
      setClubbedFiltersForSecondCity(filterResponse);
    } else if (segmentIndex == 2) {
      setClubbedFiltersForThirdCity(filterResponse);
    }

    return searchResponse;
  } catch (error) {
    console.log("Search Clubbed Flight Auth API Error ", error);
  }
};

const handleSearchClubbedFlightsNoAuth = async (
  segmentIndex,
  packages,
  setClubbedFiltersForSecondCity,
  setClubbedFiltersForThirdCity
) => {
  try {
    const {
      data: {
        data: { searchResponse, filterResponse },
      },
    } = await axios.post(APIs.getClubbedFlights, { segmentIndex, packages });

    if (segmentIndex == 1) {
      setClubbedFiltersForSecondCity(filterResponse);
    } else if (segmentIndex == 2) {
      setClubbedFiltersForThirdCity(filterResponse);
    }

    return searchResponse;
  } catch (error) {
    console.log("Search Clubbed Flight No Auth API Error ", error);
  }
};

export const getAirports = async ({ limit, skip, search = "" }) => {
  try {
    const {
      data: {
        data: { airports },
      },
    } = await axios.get(
      `${APIs.getAirports}?limit=${limit}&skip=${skip}&search=${search}`
    );
    return airports;
  } catch (error) {
    console.log("Get Airports API Error ", error);
  }
};

export const getAirport = async ({ airportCode }) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getAirport}?airportCode=${airportCode}`);
    return data;
  } catch (error) {
    console.log("Get Airport API Error ", error);
  }
};

export const getFlightDetails = async ({
  traceId,
  resultIndex,
  additionalDetails,
  token = "",
  cabinClass,
  airlines,
  pf,
  SetRateOfExchange,
}) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleGetFlightDetailsAuth(
      traceId,
      resultIndex,
      additionalDetails,
      config,
      cabinClass,
      airlines,
      pf,
      SetRateOfExchange
    );
  } else {
    return handleGetFlightDetailsNoAuth(
      traceId,
      resultIndex,
      additionalDetails,
      cabinClass,
      airlines,
      pf,
      SetRateOfExchange
    );
  }
};

const handleGetFlightDetailsAuth = async (
  traceId,
  resultIndex,
  additionalDetails,
  config,
  cabinClass,
  airlines,
  pf,
  SetRateOfExchange
) => {
  try {
    const ResultIndex = JSON.parse(resultIndex);
    const Airlines = JSON.parse(airlines);
    const Amount = JSON.parse(pf);
    const res = [];
    let rateOfExchange = 1;
    const convenienceFeeDetails = [];
    const markupDetails = [];
    for (let i = 0; i < ResultIndex.length; i++) {
      const {
        data: {
          statusCode,
          data: { Results, markup, convenienceFee, LocalCurrencyROE },
        },
      } = await axios.post(
        `${APIs.getFlightDetailsAuth}`,
        {
          TraceId: traceId,
          ResultIndex: ResultIndex[i],
          additionalDetails,
          cabinClass,
          airlines: [Airlines[i]],
          transactionAmount: Number(Amount[i]),
        },
        config
      );
      if (statusCode === 200) {
        res.push(Results);
        markupDetails.push(markup);
        convenienceFeeDetails.push(convenienceFee);
        SetRateOfExchange(LocalCurrencyROE);
        rateOfExchange = LocalCurrencyROE;
      }
    }
    return { res, markupDetails, convenienceFeeDetails, rateOfExchange };
  } catch (error) {
    console.log("Get Flight Details Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetFlightDetailsNoAuth = async (
  traceId,
  resultIndex,
  additionalDetails,
  cabinClass,
  airlines,
  pf,
  SetRateOfExchange
) => {
  try {
    const ResultIndex = JSON.parse(resultIndex);
    const Airlines = JSON.parse(airlines);
    const Amount = JSON.parse(pf);
    const res = [];
    const convenienceFeeDetails = [];
    const markupDetails = [];
    let rateOfExchange = 1;
    for (let i = 0; i < ResultIndex.length; i++) {
      const {
        data: {
          statusCode,
          data: { Results, markup, convenienceFee, LocalCurrencyROE },
        },
      } = await axios.post(`${APIs.getFlightDetails}`, {
        TraceId: traceId,
        ResultIndex: ResultIndex[i],
        additionalDetails,
        cabinClass,
        airlines: [Airlines[i]],
        transactionAmount: Number(Amount[i]),
      });
      if (statusCode === 200) {
        res.push(Results);
        markupDetails.push(markup);
        convenienceFeeDetails.push(convenienceFee);
        SetRateOfExchange(LocalCurrencyROE);
        rateOfExchange = LocalCurrencyROE;
      }
    }
    return { res, markupDetails, convenienceFeeDetails, rateOfExchange };
  } catch (error) {
    console.log("Get Flight Details No Auth API Error ", error);
  }
};

export const getInsuranceDetails = async ({
  adultCount = 1,
  childrenCount = 0,
  infantCount = 0,
}) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.getInsurance}`, {
      adultCount,
      infantCount,
      childrenCount,
    });
    if (statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log("Get Insurance API Error ", error);
  }
};

export const getSsrDetails = async ({
  traceId,
  resultIndex,
  isLcc,
  additionalDetails,
  token = "",
}) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleGetSsrDetailsAuth(
      traceId,
      resultIndex,
      isLcc,
      additionalDetails,
      config
    );
  } else {
    return handleGetSsrDetailsNoAuth(
      traceId,
      resultIndex,
      isLcc,
      additionalDetails
    );
  }
};

const handleGetSsrDetailsAuth = async (
  traceId,
  resultIndex,
  isLcc,
  additionalDetails,
  config
) => {
  try {
    const ResultIndex = JSON.parse(resultIndex);
    const isLCC = JSON.parse(isLcc);
    const res = [];
    for (let i = 0; i < ResultIndex.length; i++) {
      const {
        data: { statusCode, data },
      } = await axios.post(
        `${APIs.getSpecialServiceRequestAuth}`,
        {
          TraceId: traceId,
          ResultIndex: ResultIndex[i],
          isLCC: isLCC[i],
          additionalDetails,
        },
        config
      );

      if (statusCode === 200) {
        res.push(data);
      }
    }
    return res;
  } catch (error) {
    console.log("Get SSR Details Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetSsrDetailsNoAuth = async (
  traceId,
  resultIndex,
  isLcc,
  additionalDetails
) => {
  try {
    const ResultIndex = JSON.parse(resultIndex);
    const isLCC = JSON.parse(isLcc);
    const res = [];
    for (let i = 0; i < ResultIndex.length; i++) {
      const {
        data: { statusCode, data },
      } = await axios.post(`${APIs.getSpecialServiceRequest}`, {
        TraceId: traceId,
        ResultIndex: ResultIndex[i],
        isLCC: isLCC[i],
        additionalDetails,
      });

      if (statusCode === 200) {
        res.push(data);
      }
    }
    return res;
  } catch (error) {
    console.log("Get SSR Details No Auth API Error ", error);
  }
};

export const makeFlightBooking = async (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.post(
      `${APIs.createFlightBooking}`,
      { bookingInitiateRequest: data },
      config
    );
    return res;
  } catch (error) {
    console.log("POST createFlightBooking API", error);
    const response = error?.response?.data;
    return response;
  }
};

export const getBookingDetails = async (bookingId, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${APIs.getBookingDetails}?booking=${bookingId}`,
      config
    );
    return data;
  } catch (err) {
    console.log("GET booking Details API", err);
    const response = err?.response?.data;
    return response;
  }
};

export const getMarkupDetails = async (id) => {
  try {
    const { data } = await axios.get(`${APIs.getMarkup}?markup=${id}`);
    return data;
  } catch (err) {
    console.log("GET Markup Details API", err);
  }
};

export const getCoupons = async (payload) => {
  try {
    const res = await axios.post(`${APIs.getCoupons}`, payload);
  } catch (error) {
    console.log("Get Coupons No Auth API Error: ", error);
  }
};

export const getAirportByAirportCode = async (code) => {
  try {
    const res = await axios.get(
      `${APIs.getAirportByAirportCode}?airportCode=${code}`
    );
    return res;
  } catch (error) {
    console.log("Get Airport By code API Error: ", error);
  }
};

export const getFlightCoupons = async (payload, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let url; 
  if(token){
    url = APIs.getFlightCouponsAuth
  }else{
    url = APIs.getFlightCouponsNoAuth
  }
  try {
    const { data } = await axios.post(url, payload, config);
    if (data?.statusCode === 200) {
      return data?.data;
    }
  } catch (error) {
    console.log("Get Flight Coupons API Error: ", error);
  }
};

export const validateCoupon = ({ traceId, coupon, token = "", flight }) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleValidateCouponAuth({
      traceId,
      coupon,
      config,
      flight,
    });
  } else {
    return handleValidateCouponNoAuth({
      traceId,
      coupon,
      flight,
    });
  }
};

export const handleValidateCouponAuth = async ({
  traceId,
  coupon,
  config,
  flight,
}) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(
      `${APIs.validateCouponAuth}`,
      { traceId, coupon, flight },
      config
    );

    if (statusCode === 200) return data;
  } catch (error) {
    console.log("Validate Coupon Auth API Error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const handleValidateCouponNoAuth = async ({
  traceId,
  coupon,
  flight,
}) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.validateCoupon}`, {
      traceId,
      coupon,
      flight,
    });

    if (statusCode === 200) return data;
  } catch (error) {
    console.log("Validate Coupon No Auth API Error: ", error);
    return error.response.data;
  }
};

export const getUserBookings = async ({ filter, token = "" }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${APIs.getUserBookings}?filter=${filter}`,
      config
    );

    if (data?.statusCode === 200) {
      return data.data;
    }
  } catch (error) {
    console.log("Get User Bookings API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const flightCancellation = async (payload, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(`${APIs.cancelFlight}`, payload, config);

    if (data?.statusCode === 200) {
      return data.data;
    }
  } catch (error) {
    console.log("Cancel Flight API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const releasePNR = async ({ payload, token = "" }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(`${APIs.releasePNR}`, payload, config);
    if (data?.statusCode === 200) {
      return data.data;
    }
  } catch (error) {
    console.log("Release PNR Flight API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const addPersonalDetails = async ({ token = "", payload }) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleAddDetailsAuth(payload, config);
  } else {
    return handleAddDetailsNoAuth(payload);
  }
};

const handleAddDetailsAuth = async (payload, config) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.addDetailsFlightAuth}`, payload, config);

    if (statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log("Add Flight Details Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleAddDetailsNoAuth = async (payload) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.addDetailsFlightNoAuth}`, payload);

    if (statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log("Add Flight Details No Auth API Error ", error);
  }
};

export const getTicketApi = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const { data } = await axios.post(`${APIs.getTicket}`, payload, config);
    return data;
  } catch (error) {
    console.log("Flight Ticket error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const flightReissue = async ({ payload, token = "" }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(`${APIs.reissueFlight}`, payload, config);

    if (data?.statusCode === 200) {
      return data.data;
    }
  } catch (error) {
    console.log("Reissue Flight API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const paymentInitiate = async (payload, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${APIs.paymentInitiate}`,
      { paymentData: payload },
      config
    );

    if (data?.statusCode === 200) {
      return data.data;
    }
  } catch (error) {
    console.log(" Flight payment API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const paymentStatus = async (checkoutId, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${APIs.paymentStatus}?checkoutId=${checkoutId}`,
      config
    );

    if (data?.statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log(" Flight payment API error: ", error);
    const response = error?.response?.data;
    return response;
  }
};
