import React, { useEffect, useState } from "react";
import { getTicketApi, paymentStatus } from "../../../../modules/flight";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import APIs from "../../../../api";
import FallBackScreen from "../../../home/fallback-screen";
import { useTranslation } from "react-i18next";
import { applyCoupon } from "../../../../modules/auth";

const PaymentLoadingScreen = () => {
  const { checkoutId } = useParams();
  // console.log("bookingIdAPI", bookingId);
  const [response, setResponse] = useState();
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [fallBackScreen, setFallBackScreen] = useState(false);
  const location = useLocation()
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const flightCouponId = searchParams.get("ci"); 
  const flightIsCouponApplied = searchParams.get("isapplied")
  const hotelCouponId = searchParams.get('hci')
  const navigate = useNavigate();
  const {t} = useTranslation()
  const token = JSON.parse(localStorage.getItem("userDetails"));
  const getPaymentStatus = async () => {
    const response = await paymentStatus(checkoutId, token.token);
    const bookingId = response.data.bookingId
    setResponse(response);
    const product = response.data.product;
    const flightPayload = {
      bookingId: response.data.bookingId,
      // paymentResponse: response.data,
    };
    const hotelBookingPayload = response.data.hotelBookingData;

    if (response.data.result.code == "000.100.112") {
      if (product == "FLIGHT") {
        const ticketResponse = await getTicketApi(flightPayload);
        if (ticketResponse.statusCode == 200) {
          const bookingIdAPI = ticketResponse.data.bookingId;
          const bookingPaymentStatus = ticketResponse.data.paymentStatus;
          if(flightIsCouponApplied == "true" && flightCouponId){
            applyCoupon(token.token, flightCouponId )
          }
          navigate(
            `/flight/bookingConfirmation/${bookingIdAPI}/${bookingPaymentStatus}`
          );
        } else {
          // setFallBackScreen(!fallBackScreen);
          navigate(
            `/flight/bookingConfirmation/${bookingId}/${"FAILED"}`
          );
          console.log("fallBackScreen", fallBackScreen);
        }
        console.log("paymentStatusResponse", response.data.bookingId);
      } else {
        hotelBookingPayload.booking = response.data.bookingId;
        // hotelBookingPayload.paymentResponse = response.data;
        const bookingResponse = await fetch(`${APIs.bookHotel}`, {
          method: "POST",
          headers: {
            Authorization: token.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(hotelBookingPayload),
        });
        const bookingResponseData = await bookingResponse.json();

        if (
          bookingResponseData.statusCode == 200 &&
          bookingResponseData?.data?.responseData?.ClientReferenceId
        ) {
          // setPaymentButtonDisabled(false);
          const id = bookingResponseData?.data?._id;
          const ConfirmationNumber =
            bookingResponseData?.data?.responseData?.ConfirmationNumber;
          
          if(hotelCouponId){
            applyCoupon(token.token, hotelCouponId)
          }
          navigate(
            `/hotel/booking-details/?id=${id}&cn=${ConfirmationNumber}&status=${"SUCCESS"}`
          );
        } else {
          // toast.error(bookingResponseData.message);
          // setPaymentButtonDisabled(false);
          navigate(
            `/hotel/booking-details/?id=${bookingId}&status=${"FAILED"}`
          );
        }
      }
    } else {
      setIsPaymentFailed(!isPaymentFailed);
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);

  return (
    <>
      {isPaymentFailed ? (
        <div>
          <FallBackScreen />
        </div>
      ) : fallBackScreen ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontFamily: "Lato",
                fontSize: "16px",
              }}
            >
              {t("paymentLoadingPage.errorTicketGeneration")}
              {/* Error, Ticket Generation Failed */}
            </p>
            <p
              style={{
                fontFamily: "Lato",
                fontSize: "16px",
              }}
            >
              {t("paymentLoadingPage.contactJoyusTeam")}
              {/* Please Contact JOYUS Team */}
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "14px",
            }}
          >
            <div>
              <Rings height={100} width={100} color="#1B1D52" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <p style={{ fontFamily: "Lato", fontSize: "18px" }}>
                {t("paymentLoadingPage.generatingTicket")}
                {/* Generating Ticket! */}
              </p>
              <p style={{ fontFamily: "Lato", fontSize: "18px" }}>
              {t("paymentLoadingPage.pleaseWait")}
                {/* Please Wait... */}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentLoadingScreen;
