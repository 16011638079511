import React from "react";
import PlaceholderImage from "../../../../../../../../../../../assets/images/logo.png";
import rightArrowIcon from "../../../../../../../../../../../assets/images/right-arrow-black.svg";
import PropTypes from "prop-types";
import "./styles.css";
import useStyles from "./style";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const ReissueCharges = ({ flight }) => {
  if (Object.keys(flight).length === 0) return <></>;
  const styles = useStyles();
  const { Origin, Destination, AirlineCode, MiniFareRules } = flight;

  return (
    <>
      <div className="cancellationCharges-parent-div">
        <p className="from-date-departure-text2">*From the Date of Departure</p>
        {/* <div className="route-parent-div">
          <div style={{ display: "flex" }}>
            <img
              className="airline-image"
              src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
              onError={(e) => {
                e.target.src = PlaceholderImage;
              }}
              style={{ height: "36px", width: "42px" }}
            ></img>
            <div style={{ display: "flex", marginTop: "4px", gap: "6px" }}>
              <div className="route-source-text">{Origin}</div>
              <div>
                <img src={rightArrowIcon} alt="right arrow icon"></img>
              </div>
              <div className="route-destination-text">{Destination}</div>
            </div>
          </div>
          <div className="from-date-departure-text">
            *From the Date of Departure
          </div>
        </div> */}
        <table className={styles.customTable}>
          <tbody>
            <tr>
              <td className={styles.customTableHeading}>
                <div>Time frame</div>
                <div className={styles.customTableMetaHeading}>
                  (From Scheduled flight departure)
                </div>
              </td>
              <td className={styles.customTableHeading}>
                <div>
                  Fare difference (if any) + Airline Fee + Zaps Service fee
                </div>
                <div className={styles.customTableMetaHeading}>
                  (Per passenger)
                </div>
              </td>
            </tr>
            {MiniFareRules &&
              MiniFareRules.length > 0 &&
              MiniFareRules.filter(
                (farerule) => farerule.Type === "Reissue"
              ).map((farerule, index) => {
                const { From, To, Unit, Details, JourneyPoints } = farerule;
                return (
                  <tr key={index}>
                    {From ? (
                      To && To.length > 0 ? (
                        <td className={styles.customTableColumn}>
                          {From} {Unit} to {To} {Unit}*&nbsp;({JourneyPoints})
                        </td>
                      ) : (
                        <td className={styles.customTableColumn}>
                          {From} {Unit}*&nbsp;({JourneyPoints})
                        </td>
                      )
                    ) : (
                      <td className={styles.customTableColumn}>
                        *From the Date of Departure
                      </td>
                    )}
                    <td className={styles.customTableColumnPrice}>
                      {Details} + Fare difference
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <p
          style={{
            color: "#525A6A",
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: "400",
            textAlign: "justify",
          }}
        >
          Please be informed that the airline fee provided is an approximate
          estimate and is subject to change. At Joyus, we strive to provide
          accurate information, but we cannot guarantee its absolute accuracy.
          Kindly note that all fees mentioned are applicable to each passenger.
        </p>
        <p
          style={{
            color: "#525A6A",
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: "400",
            textAlign: "justify",
          }}
        >
          Please take note that choosing the same airline for a different date
          incurs date change charges. Any difference in fares between the
          original and revised booking will be the user’s responsibility. Find
          details about allowed free date changes, if applicable, in the Date
          Change Charges section. Your seamless travel experience awaits with
          Joyus!
        </p>
      </div>
    </>
  );
};

export default ReissueCharges;

ReissueCharges.propTypes = {
  flight: PropTypes.object,
};
