import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useStyles from "./styles";
import { TwoToneTitle } from "../../../ui/components/TwoToneTitle";
import { map } from "lodash";
import { Flight } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { getRecentSearches } from "../../../modules/home";
import moment from "moment";
// import 'moment/locale/ar'; // Import Arabic locale
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAirport } from "../../../modules/flight";
import flightIcon from "../../../assets/images/plane-horizontal-white.svg";
import {
  handleMultiCityDates,
  updateModifyDates,
  updateModifyFlightFrom,
  updateModifyFlightTo,
  updateModifyFlightTravelType,
} from "../../../utils/slices/modifySearchFlightSlice";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { get } from "lodash";
import {
  updateQueryFlightFrom,
  updateQueryFlightTo,
} from "../../../utils/slices/searchFlightQuerySlice";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import { useTranslation } from "react-i18next";
import { getTimeDifferenceInArabic } from "../../../utils/helpers";

const RecentSearches = () => {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [recentSearches, setRecentSearches] = useState([]);
  const uuid = localStorage.getItem("uuid");
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));

  const recentSearchesHandler = async () => {
    const token = userDetails?.token;
    const response = await getRecentSearches(token, uuid);

    const statusCode = response?.statusCode;
    const message = response?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    setRecentSearches(response?.flightSearches);
  };
  useEffect(() => {
    recentSearchesHandler();
  }, []);

  const handleGetAirport = async (from, to, index) => {
    const fromAirport = await getAirport({ airportCode: from });
    if (fromAirport?.data) {
      dispatch(
        updateModifyFlightFrom({ id: index, data: fromAirport.data?.data })
      );
    }
    const toAirport = await getAirport({ airportCode: to });
    if (toAirport?.data) {
      dispatch(updateModifyFlightTo({ id: index, data: toAirport.data?.data }));
    }
  };

  return (
    <div className={styles.recentSearch}>
      {recentSearches && recentSearches.length > 0 && (
        <div className={styles.title}>
          <TwoToneTitle
            firstPart={t("recentSearches.yourRecent")}
            secondPart={t("recentSearches.searches")}
          />
        </div>
      )}
      <div className={styles.recentSearchCards}>
        {isMobile || isTablet ? (
          <Carousel itemsToShow={1} enableSwipe={false}>
            {map(recentSearches, (cardData) => {
              const nonStop = cardData.oneStopFlight == true ? false : true;
              const createdAt = moment(cardData.createdAt);
              const timeAgo = createdAt.fromNow();
              const timeAgoArabic = getTimeDifferenceInArabic(cardData.createdAt)
              const arrivalDate =
                cardData.flightSegments[0].preferredArrivalTime;
              const departureDate =
                cardData.journeyType == "2"
                  ? cardData.flightSegments[1].preferredDepartureTime
                  : cardData.flightSegments[0].preferredDepartureTime;
              const parsedArrivalDate = moment(arrivalDate, "DD/MM/YYYY");

              const formattedArrivalDate =
                parsedArrivalDate.format("ddd, DD MMM");
              const parsedDepartureDate = moment(departureDate, "DD/MM/YYYY");
              const formattedDepartureDate =
                parsedDepartureDate.format("ddd, DD MMM");

              const isoDateArrival = new Date(parsedArrivalDate);
              const formattedArrivalDateArabic = isoDateArrival.toLocaleString("ar", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })

              const isoDateDeparture = new Date(parsedDepartureDate);
              const formattedDepartureDateArabic = isoDateDeparture.toLocaleString("ar", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
              return (
                <div key={cardData._id} className={styles.recentSearchCard}>
                  <div className={styles.recentSearchCardLeftSection}>
                    <p className={styles.flightType}>
                      {cardData.journeyType == "1"
                        ?
                        // "One way "
                        t("recentSearches.oneWay")
                        : cardData.journeyType == "2"
                          ?
                          // "Round Trip"
                          t("recentSearches.roundTrip")
                          :
                          // "Multi-City"
                          t("recentSearches.multiCity")
                      }
                    </p>
                    <div className={styles.recentSearchCardFlightPath}>
                      <Flight style={{ fontSize: "16px" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        {map(cardData.flightSegments, (flightData, index) => (
                          <div key={index} className={styles.travelCities}>
                            <span
                              className={
                                cardData.journeyType == "3"
                                  ? styles.cardMultiCityName
                                  : styles.cardCityName
                              }
                            >
                              <p>{flightData.origin}</p>
                            </span>
                            {localStorage.getItem("language") == "AR"
                              ?
                              <ArrowBackIcon
                                style={{ fontSize: "16px", fontWeight: "12px" }} />
                              : <ArrowForwardIcon
                                style={{ fontSize: "16px", fontWeight: "12px" }}
                              />}

                            <span
                              className={
                                cardData.journeyType == "3"
                                  ? styles.cardMultiCityName
                                  : styles.cardCityName
                              }
                            >
                              {flightData.destination}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <p className={styles.flightDateAndTime}>
                      {/* {(() => {
                        console.log('formattedArrivalDate', formattedArrivalDate)
                        console.log('formattedDepartureDate', formattedDepartureDate)
                        console.log('formattedArrivalDate} - {formattedDepartureDate',
                          formattedArrivalDate - formattedDepartureDate)
                      })()} */}
                      {/* { formattedArrivalDate } - { formattedDepartureDate } */}
                      {
                        localStorage.getItem('language') != "AR"
                          ? formattedArrivalDate
                          : formattedArrivalDateArabic
                      }
                    </p>
                    <p className={styles.cardFlightType}>
                      {cardData.flightSegments[0].cabinClass == "2"
                        ? `
                        ${t("recentSearches.economy")} 
                        ${cardData.adultCount +
                        cardData.infantCount +
                        cardData.childCount
                        } ${t("recentSearches.traveller")} 
                        `
                        : cardData.flightSegments[0].cabinClass == "3"
                          ? `${t("recentSearches.premiumEconomy")} ${cardData.adultCount +
                          cardData.infantCount +
                          cardData.childCount
                          } ${t("recentSearches.traveller")} `
                          : cardData.flightSegments[0].cabinClass == "4"
                            ? `${t("recentSearches.bussiness")} ${cardData.adultCount +
                            cardData.infantCount +
                            cardData.childCount
                            } ${t("recentSearches.traveller")} `
                            : cardData.flightSegments[0].cabinClass == "5"
                              ? `${t("recentSearches.premiumBussiness")}  ${cardData.adultCount +
                              cardData.infantCount +
                              cardData.childCount
                              } ${t("recentSearches.traveller")} `
                              : `${t("recentSearches.firstClass")} ${cardData.adultCount +
                              cardData.infantCount +
                              cardData.childCount
                              } ${t("recentSearches.traveller")} `}
                    </p>
                  </div>
                  <div className={styles.recentSearchCardRightSection}>
                    <p className={styles.flightType}>
                      {/* {timeAgo} */}
                      {localStorage.getItem('language') != "AR" ? timeAgo : timeAgoArabic}
                    </p>
                    <div className={styles.flightIconWrapper}>
                      <ReactSVG src={flightIcon} />
                    </div>

                    <>
                      <p
                        className={styles.cardSearchAction}
                        onClick={() => {
                          if (cardData.journeyType == 1) {
                            handleGetAirport(
                              cardData.flightSegments[0].origin,
                              cardData.flightSegments[0].destination,
                              0
                            );
                            dispatch(
                              updateModifyDates({
                                departureDate:
                                  cardData.flightSegments[0]
                                    .preferredDepartureTime,
                                returnDate: "",
                              })
                            );
                            dispatch(updateModifyFlightTravelType(1));
                            const {
                              origin,
                              destination,
                              preferredDepartureTime,
                            } = cardData.flightSegments[0];
                            const { cabinClass } = cardData;
                            const { adultCount, childCount, infantCount } =
                              cardData;
                            dispatch(
                              updateQueryFlightFrom({ id: 0, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 1, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 2, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 2, data: {} }));

                            navigate(
                              `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}&tt=O&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                            );
                          } else if (cardData.journeyType == 2) {
                            handleGetAirport(
                              cardData.flightSegments[0].origin,
                              cardData.flightSegments[0].destination,
                              0
                            );
                            dispatch(
                              updateModifyDates({
                                departureDate:
                                  cardData.flightSegments[0]
                                    .preferredDepartureTime,
                                returnDate:
                                  cardData.flightSegments[1]
                                    .preferredArrivalTime,
                              })
                            );
                            dispatch(updateModifyFlightTravelType(2));
                            const {
                              origin,
                              destination,
                              preferredDepartureTime,
                              cabinClass,
                            } = cardData.flightSegments[0];
                            const { preferredArrivalTime } =
                              cardData.flightSegments[1];
                            const { adultCount, childCount, infantCount } =
                              cardData;
                            dispatch(
                              updateQueryFlightFrom({ id: 0, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 1, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 2, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                            navigate(
                              `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${destination}-${origin}-${preferredArrivalTime}&tt=R&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                            );
                          } else if (cardData.journeyType == 3) {
                            const { adultCount, childCount, infantCount } =
                              cardData;

                            let multiDates = [];
                            for (
                              let i = 0;
                              i < cardData.flightSegments.length;
                              i++
                            ) {
                              handleGetAirport(
                                cardData.flightSegments[i].origin,
                                cardData.flightSegments[i].destination,
                                i
                              );
                              if (i == 0) {
                                dispatch(
                                  updateModifyDates({
                                    departureDate:
                                      cardData.flightSegments[0].departureDate,
                                    returnDate: "",
                                  })
                                );
                              } else {
                                multiDates.push({
                                  key: i,
                                  date: cardData.flightSegments[i]
                                    .departureDate,
                                });
                              }
                            }
                            dispatch(updateModifyFlightTravelType(3));
                            dispatch(handleMultiCityDates(multiDates));

                            if (cardData.flightSegments.length == 2) {
                              const {
                                origin,
                                destination,
                                preferredDepartureTime,
                              } = cardData.flightSegments[0];
                              const { cabinClass } = cardData;
                              const {
                                origin: sOrigin,
                                destination: sDestination,
                                preferredDepartureTime: sDeparture,
                              } = cardData.flightSegments[1];

                              dispatch(
                                updateQueryFlightFrom({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 2, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 2, data: {} })
                              );
                              navigate(
                                `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${sOrigin}-${sDestination}-${sDeparture}&tt=M&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                              );
                            } else if (cardData.flightSegments.length === 3) {
                              const {
                                origin,
                                destination,
                                preferredDepartureTime,
                                cabinClass,
                              } = cardData.flightSegments[0];
                              const {
                                origin: sOrigin,
                                destination: sDestination,
                                preferredDepartureTime: sDeparture,
                              } = cardData.flightSegments[1];
                              const {
                                origin: tOrigin,
                                destination: tDestination,
                                preferredDepartureTime: tDeparture,
                              } = cardData.flightSegments[2];
                              dispatch(
                                updateQueryFlightFrom({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 2, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 2, data: {} })
                              );
                              navigate(
                                `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${sOrigin}-${sDestination}-${sDeparture}_${tOrigin}-${tDestination}-${tDeparture}&tt=M&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                              );
                            }
                          }
                        }}
                      >
                        {/* Search */}
                        {t("recentSearches.search")}
                      </p>
                    </>
                  </div>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <>
            {map(recentSearches, (cardData) => {
              const nonStop = cardData.oneStopFlight == true ? false : true;
              const createdAt = moment(cardData.createdAt);
              const timeAgo = createdAt.fromNow();
              const timeAgoArabic = getTimeDifferenceInArabic(cardData.createdAt)
              const arrivalDate =
                cardData.flightSegments[0].preferredArrivalTime;
              const departureDate =
                cardData.journeyType == "2"
                  ? cardData.flightSegments[1].preferredDepartureTime
                  : cardData.flightSegments[0].preferredDepartureTime;
              const parsedArrivalDate = moment(arrivalDate, "DD/MM/YYYY");


              const isoDateArrival = new Date(parsedArrivalDate);
              const formattedArrivalDateArabic = isoDateArrival.toLocaleString("ar", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })

              const isoDateDeparture = new Date(parsedArrivalDate);
              const formattedDepartureDateArabic = isoDateDeparture.toLocaleString("ar", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })


              const formattedArrivalDate =
                parsedArrivalDate.format("ddd, DD MMM");
              const parsedDepartureDate = moment(departureDate, "DD/MM/YYYY");
              const formattedDepartureDate =
                parsedDepartureDate.format("ddd, DD MMM");
              return (
                <div
                  key={cardData._id}
                  className={
                    recentSearches.length > 3
                      ? styles.recentSearchCard
                      : styles.recentSearchCard1
                  }
                >
                  <div className={styles.recentSearchCardLeftSection}>
                    <p className={styles.flightType}>
                      {cardData.journeyType == "1"
                        ?
                        // "One way "
                        t("recentSearches.oneWay")
                        : cardData.journeyType == "2"
                          ?
                          // "Round Trip"
                          t("recentSearches.roundTrip")
                          :
                          // "Multi-City"
                          t("recentSearches.multiCity")
                      }
                    </p>
                    <div className={styles.recentSearchCardFlightPath}>
                      <Flight style={{ fontSize: "16px" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        {map(cardData.flightSegments, (flightData, index) => (
                          <div key={index} className={styles.travelCities}>
                            <span
                              className={
                                cardData.journeyType == "3"
                                  ? styles.cardMultiCityName
                                  : styles.cardCityName
                              }
                            >
                              <p>{flightData.origin}</p>
                            </span>
                            {localStorage.getItem("language") == "AR"
                              ?
                              <ArrowBackIcon
                                style={{ fontSize: "16px", fontWeight: "12px" }} />
                              : <ArrowForwardIcon
                                style={{ fontSize: "16px", fontWeight: "12px" }}
                              />}
                            <span
                              className={
                                cardData.journeyType == "3"
                                  ? styles.cardMultiCityName
                                  : styles.cardCityName
                              }
                            >
                              {flightData.destination}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <p className={styles.flightDateAndTime}>

                      {
                        localStorage.getItem('language') != "AR"
                          ? cardData.journeyType == "1"
                            ? formattedArrivalDate
                            : `${formattedArrivalDate} - ${formattedDepartureDate}`

                          : cardData.journeyType == "1"
                            ? formattedArrivalDateArabic
                            : `${formattedArrivalDateArabic} - ${formattedDepartureDateArabic}`

                      }
                      {/* {(() => {
                        console.log('localStorage.getItem("languague")'
                          , localStorage.getItem("language"))
                      })()} */}
                      {/* {cardData.journeyType == "1" && localStorage.getItem("language") == "EN"
                        ? formattedArrivalDate
                        : `${formattedArrivalDate} - ${formattedDepartureDate}`}
                      {cardData.journeyType == "1" && localStorage.getItem("language") == "AR"
                        ? formattedArrivalDateArabic
                        : `${formattedArrivalDateArabic} - ${formattedDepartureDateArabic}`} */}
                    </p>
                    <p className={styles.cardFlightType}>
                      {cardData.flightSegments[0].cabinClass == "2"
                        ? `
                        ${t("recentSearches.economy")} 
                        ${cardData.adultCount +
                        cardData.infantCount +
                        cardData.childCount
                        } ${t("recentSearches.traveller")} 
                        `
                        : cardData.flightSegments[0].cabinClass == "3"
                          ? `${t("recentSearches.premiumEconomy")} ${cardData.adultCount +
                          cardData.infantCount +
                          cardData.childCount
                          } ${t("recentSearches.traveller")} `
                          : cardData.flightSegments[0].cabinClass == "4"
                            ? `${t("recentSearches.bussiness")} ${cardData.adultCount +
                            cardData.infantCount +
                            cardData.childCount
                            } ${t("recentSearches.traveller")} `
                            : cardData.flightSegments[0].cabinClass == "5"
                              ? `${t("recentSearches.premiumBussiness")}  ${cardData.adultCount +
                              cardData.infantCount +
                              cardData.childCount
                              } ${t("recentSearches.traveller")} `
                              : `${t("recentSearches.firstClass")} ${cardData.adultCount +
                              cardData.infantCount +
                              cardData.childCount
                              } ${t("recentSearches.traveller")} `}
                    </p>
                  </div>
                  <div className={styles.recentSearchCardRightSection}>
                    <p className={styles.flightType}>
                      {/* {timeAgo} */}
                      {localStorage.getItem('language') != "AR" ? timeAgo : timeAgoArabic}
                    </p>
                    <div className={styles.flightIconWrapper}>
                      <ReactSVG src={flightIcon} />
                    </div>

                    <>
                      <p
                        className={styles.cardSearchAction}
                        onClick={() => {
                          if (cardData.journeyType == 1) {
                            handleGetAirport(
                              cardData.flightSegments[0].origin,
                              cardData.flightSegments[0].destination,
                              0
                            );
                            dispatch(
                              updateModifyDates({
                                departureDate:
                                  cardData.flightSegments[0]
                                    .preferredDepartureTime,
                              })
                            );
                            dispatch(updateModifyFlightTravelType(1));
                            dispatch(
                              updateQueryFlightFrom({ id: 0, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 1, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 2, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                            const {
                              origin,
                              destination,
                              preferredDepartureTime,
                            } = cardData.flightSegments[0];
                            const { cabinClass } = cardData;
                            const { adultCount, childCount, infantCount } =
                              cardData;
                            navigate(
                              `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}&tt=O&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                            );
                          } else if (cardData.journeyType == 2) {
                            handleGetAirport(
                              cardData.flightSegments[0].origin,
                              cardData.flightSegments[0].destination,
                              0
                            );
                            dispatch(
                              updateModifyDates({
                                departureDate:
                                  cardData.flightSegments[0]
                                    .preferredDepartureTime,
                                returnDate:
                                  cardData.flightSegments[1]
                                    .preferredArrivalTime,
                              })
                            );
                            dispatch(updateModifyFlightTravelType(2));
                            const {
                              origin,
                              destination,
                              preferredDepartureTime,
                            } = cardData.flightSegments[0];
                            const { cabinClass } = cardData;
                            const { preferredArrivalTime } =
                              cardData.flightSegments[1];
                            const { adultCount, childCount, infantCount } =
                              cardData;
                            dispatch(
                              updateQueryFlightFrom({ id: 0, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 1, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 2, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                            navigate(
                              `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${destination}-${origin}-${preferredArrivalTime}&tt=R&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                            );
                          } else if (cardData.journeyType == 3) {
                            const { adultCount, childCount, infantCount } =
                              cardData;

                            let multiDates = [];
                            for (
                              let i = 0;
                              i < cardData.flightSegments.length;
                              i++
                            ) {
                              handleGetAirport(
                                cardData.flightSegments[i].origin,
                                cardData.flightSegments[i].destination,
                                i
                              );
                              if (i == 0) {
                                dispatch(
                                  updateModifyDates({
                                    departureDate:
                                      cardData.flightSegments[0]
                                        .preferredDepartureTime,
                                  })
                                );
                              } else {
                                multiDates.push({
                                  key: i,
                                  date: cardData.flightSegments[i]
                                    .preferredDepartureTime,
                                });
                              }
                            }
                            dispatch(updateModifyFlightTravelType(3));
                            dispatch(handleMultiCityDates(multiDates));
                            dispatch(
                              updateQueryFlightFrom({ id: 0, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 1, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                            dispatch(
                              updateQueryFlightFrom({ id: 2, data: {} })
                            );
                            dispatch(updateQueryFlightTo({ id: 2, data: {} }));

                            if (cardData.flightSegments.length == 2) {
                              const {
                                origin,
                                destination,
                                preferredDepartureTime,
                              } = cardData.flightSegments[0];
                              const { cabinClass } = cardData;
                              const {
                                origin: sOrigin,
                                destination: sDestination,
                                preferredDepartureTime: sDeparture,
                              } = cardData.flightSegments[1];

                              navigate(
                                `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${sOrigin}-${sDestination}-${sDeparture}&tt=M&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                              );
                            } else if (cardData.flightSegments.length === 3) {
                              const {
                                origin,
                                destination,
                                preferredDepartureTime,
                              } = cardData.flightSegments[0];

                              const { cabinClass } = cardData;
                              const {
                                origin: sOrigin,
                                destination: sDestination,
                                preferredDepartureTime: sDeparture,
                              } = cardData.flightSegments[1];
                              const {
                                origin: tOrigin,
                                destination: tDestination,
                                preferredDepartureTime: tDeparture,
                              } = cardData.flightSegments[2];

                              dispatch(
                                updateQueryFlightFrom({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 0, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 1, data: {} })
                              );
                              dispatch(
                                updateQueryFlightFrom({ id: 2, data: {} })
                              );
                              dispatch(
                                updateQueryFlightTo({ id: 2, data: {} })
                              );

                              navigate(
                                `/flight/search?itinerary=${origin}-${destination}-${preferredDepartureTime}_${sOrigin}-${sDestination}-${sDeparture}_${tOrigin}-${tDestination}-${tDeparture}&tt=M&pt=A-${adultCount}_C-${childCount}_I-${infantCount}&cc=${cabinClass}&ns=${nonStop}`
                              );
                            }
                          }
                        }}
                      >
                        {/* Search */}
                        {t("recentSearches.search")}
                      </p>
                    </>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentSearches;
