import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlightResultSubHeader from "../../flight-result-sub-header";
import BookFlight from "./book-flight";
import BookFlightMobile from "./book-flight-mobile";
import useResponsive from "../../../../../../hooks/responsive.hook";
import {
  filterFlightsHelperFunctionForOneWay,
  sortFlightsOnPrice,
} from "../../helper";
import {
  handleFilteredFlights,
  handleTotalFlights,
} from "../../../../../../utils/slices/flightFiltersSlice";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import { useNavigate } from "react-router-dom";
import FlightNotFound from "../flight-not-found";
import { handleSearchedFlightsForOneWay } from "../../../../../../utils/slices/onewaySlice";

const OneWayTripContainer = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const currentBreakpoint = useResponsive();
  const navigate = useNavigate();

  const { searchedFlights } = useSelector((state) => state.oneWayTripDetails);
  const {
    flightTotalTravellers: {
      flightAdultsCount,
      flightInfantsCount,
      flightChildrenCount,
    },
  } = useSelector((state) => state.modifySearchFlight);

  const {
    stopsFilter: stops,
    airlineFilter: airlines,
    refundFilter: refundOption,
    priceFilter: price,
    durationFilter: duration,
    outBoundDepartureDurationFilter: outBoundDepartureDuration,
    outBoundArrivalDurationFilter: outBoundArrivalDuration,
    filteredFlights,
    sort,
  } = useSelector((state) => state.flightFilters);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  const { flightClassType, flightDepartureDate, flightFrom, flightTo } =
    useSelector((state) => state.searchFlightQuery);
  const language = useSelector((state) => state.locale.language);
  const handleBookNow = async (flight, markupDetails) => {
    const airlineCode = JSON.stringify([flight.ValidatingAirline]);
    const markup = getMarkup(markupDetails, flight);
    const markupId = markupDetails?._id;
    const traceId = window.localStorage.getItem("traceId");
    const resultIndex = JSON.stringify([flight?.ResultId]);
    const isLcc = JSON.stringify([flight?.IsLcc]);
    const fare = JSON.stringify([
      // Math.ceil(flight?.Fare?.PublishedFare) + markup,
      Math.ceil(flight?.Fare?.TotalFare * rateOfExchange) + markup,
    ]);
    const isInternational = flightFrom[0].country != flightTo[0].country;

    isMobile
      ? navigate(
          `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${1}/${"OWT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}/?ff=${
            flightFrom[0].code
          }&ft=${
            flightTo[0].code
          }&dd=${flightDepartureDate}&isIt=${isInternational}&lang=&${language}`
        )
      : window.open(
          `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${1}/${"OWT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}/?ff=${
            flightFrom[0].code
          }&ft=${
            flightTo[0].code
          }&dd=${flightDepartureDate}&isIt=${isInternational}&lang=${language}`
        );
  };

  const getMarkup = (markupDetails, flight) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        let baseFare =
          flight?.Fare?.TotalFare -
          (flight?.Fare?.Tax + flight?.Fare?.VATAmount);
        amt =
          (amt * (baseFare * rateOfExchange + flight?.Fare?.OtherCharges)) /
          100;
      } else {
        amt = (amt * (flight?.Fare?.TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    return Math.ceil(amt);
  };

  useEffect(() => {
    const filteredFlights = filterFlightsHelperFunctionForOneWay({
      stops,
      airlines,
      refundOption,
      price,
      duration,
      flights: searchedFlights,
      index: 0,
      outBoundDepartureDuration,
      outBoundArrivalDuration,
      rateOfExchange,
    });
    dispatch(handleFilteredFlights(filteredFlights));
    dispatch(handleTotalFlights(searchedFlights.length));
  }, [
    stops,
    airlines,
    refundOption,
    price,
    duration,
    outBoundDepartureDuration,
    outBoundArrivalDuration,
  ]);

  useEffect(() => {
    const flights =
      filteredFlights.length > 0 ? filteredFlights : searchedFlights;
    const sortedFlights = sortFlightsOnPrice(sort, flights, rateOfExchange);

    if (filteredFlights.length > 0) {
      dispatch(handleFilteredFlights(sortedFlights));
    } else dispatch(handleSearchedFlightsForOneWay(sortedFlights));
  }, [sort]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {currentBreakpoint === "extraLarge" && <FlightResultSubHeader />}

      {stops.length > 0 ||
      airlines.length > 0 ||
      refundOption.length > 0 ||
      price.length > 0 ||
      duration.length > 0 ||
      outBoundDepartureDuration.length > 0 ||
      outBoundArrivalDuration.length > 0 ? (
        filteredFlights.length > 0 ? (
          filteredFlights?.map((flight, i) => {
            return isMobile ? (
              <BookFlightMobile
                flight={flight}
                key={i}
                handleBookNow={handleBookNow}
              />
            ) : (
              <BookFlight
                flight={flight}
                key={i}
                handleBookNow={handleBookNow}
              />
            );
          })
        ) : (
          <FlightNotFound />
        )
      ) : (
        searchedFlights?.map((flight, i) => {
          return isMobile ? (
            <BookFlightMobile
              flight={flight}
              key={i}
              handleBookNow={handleBookNow}
            />
          ) : (
            <BookFlight
              flight={flight}
              index={i}
              key={i}
              handleBookNow={handleBookNow}
            />
          );
        })
      )}
    </div>
  );
};
export default OneWayTripContainer;
