import moment from "moment";
import { convertTimeToMinutes } from "../../../../utils/helpers";
import CryptoJS from "crypto-js";

export const filterFlightsHelperFunctionForOneWay = ({
  stops = [],
  airlines = [],
  refundOption = [],
  price = [],
  duration = [],
  flights,
  index,
  outBoundDepartureDuration = [],
  outBoundArrivalDuration = [],
  rateOfExchange = 1,
}) => {
  let filteredFlights = flights;
  let isAnyFilteredFlightFound = false;

  if (stops.length > 0) {
    filteredFlights = filteredFlights.filter((flight) =>
      stops.includes(flight[0]?.Segments[index]?.length - 1)
    );
    if (filteredFlights?.length > 0) isAnyFilteredFlightFound = true;
  }

  if (airlines.length > 0) {
    filteredFlights = filteredFlights.filter((flight) =>
      airlines.includes(
        flight[0]?.Segments[index][0]?.AirlineDetails?.AirlineName
      )
    );
    if (filteredFlights?.length > 0) isAnyFilteredFlightFound = true;
  }

  if (refundOption.length > 0) {
    const isRefundable = refundOption.includes(1) || refundOption.includes(3);
    const nonRefundable = refundOption.includes(2);
    if (isRefundable && nonRefundable == false) {
      let dummyArray = [];
      filteredFlights.map((flight) => {
        if (flight[0]?.NonRefundable == false) {
          dummyArray.push(flight);
        }
      });
      if (dummyArray?.length > 0) isAnyFilteredFlightFound = true;
      filteredFlights = dummyArray;
    } else if (nonRefundable && isRefundable == false) {
      let dummyArray = [];
      filteredFlights.map((flight) => {
        if (flight[0]?.NonRefundable == true) {
          dummyArray.push(flight);
        }
      });

      if (dummyArray?.length > 0) isAnyFilteredFlightFound = true;
      filteredFlights = dummyArray;
    } else {
      isAnyFilteredFlightFound = true;
    }
  }

  if (price.length > 0) {
    let flightsDummyArray = [];
    filteredFlights.filter((flights) => {
      const value = flights.filter((flight) => {
        const markup = getMarkup(flight?.markup, flight, rateOfExchange);

        if (
          Math.ceil(flight?.Fare?.TotalFare * rateOfExchange) + markup >=
            Math.ceil(price[0]) &&
          Math.ceil(flight?.Fare?.TotalFare * rateOfExchange) + markup <=
            Math.ceil(price[1])
        ) {
          return true;
        }
      });

      if (value?.length >= 1) {
        flightsDummyArray.push(flights);
      }
    });
    if (flightsDummyArray?.length > 0) isAnyFilteredFlightFound = true;
    filteredFlights = flightsDummyArray;
  }

  if (duration.length > 0) {
    let flightsDummyArray = [];
    let totalDuration = 0;
    filteredFlights.filter((flights) => {
      const value = flights.filter((flight) => {
        totalDuration = 0;
        flight?.Segments[index]?.map((segment) => {
          // console.log(
          //   "segmentDuration",
          //   convertTimeToMinutes(segment?.Duration)
          // );
          totalDuration += convertTimeToMinutes(segment?.Duration);
        });
        if (totalDuration >= duration[0] && totalDuration <= duration[1])
          return true;
      });
      if (value?.length >= 1) {
        flightsDummyArray.push(flights);
      }
    });
    if (flightsDummyArray?.length > 0) isAnyFilteredFlightFound = true;
    filteredFlights = flightsDummyArray;
  }

  if (outBoundDepartureDuration.length > 0) {
    let flightsDummyArray = [];
    filteredFlights.filter((flights) => {
      const value = flights.filter((flight) => {
        const time = flight?.Segments[index][0]?.DepartureTime;
        const departureTime = moment(time);
        const hour = departureTime.hour();
        const timeFrame = handleTimeFrame(hour);
        if (outBoundDepartureDuration.includes(timeFrame)) return true;
      });
      if (value?.length >= 1) {
        flightsDummyArray.push(flights);
      }
    });
    if (flightsDummyArray?.length > 0) isAnyFilteredFlightFound = true;
    filteredFlights = flightsDummyArray;
  }

  if (outBoundArrivalDuration.length > 0) {
    let flightsDummyArray = [];
    filteredFlights.filter((flights) => {
      const value = flights.filter((flight) => {
        const time =
          flight?.Segments[index][flight?.Segments[index].length - 1]
            ?.ArrivalTime;
        const departureTime = moment(time);
        const hour = departureTime.hour();
        const timeFrame = handleTimeFrame(hour);
        if (outBoundArrivalDuration.includes(timeFrame)) return true;
      });
      if (value?.length >= 1) {
        flightsDummyArray.push(flights);
      }
    });
    if (flightsDummyArray?.length > 0) isAnyFilteredFlightFound = true;
    filteredFlights = flightsDummyArray;
  }

  if (isAnyFilteredFlightFound == false) filteredFlights = [];
  return filteredFlights;
};

export const filterFlightsHelperFunctionForDomesticRoundTrip = ({
  airlines = [],
  stops = [],
  price = [],
  duration = [],
  refundOption = [],
  outboundFlights,
  inboundFlights,
  outBoundDepartureDuration,
  outBoundArrivalDuration,
  inBoundDepartureDuration,
  inBoundArrivalDuration,
  markupDetails = [],
}) => {
  let filteredFlightsOutbound = outboundFlights;
  let filteredFlightsInbound = inboundFlights;
  let isAnyFilteredFlightOutboundFound = false;
  let isAnyFilteredFlightInboundFound = false;

  if (stops.length > 0) {
    filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) =>
      stops.includes(flight?.Segments[0]?.length - 1)
    );

    filteredFlightsInbound = filteredFlightsInbound.filter((flight) =>
      stops.includes(flight?.Segments[0]?.length - 1)
    );

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
  }

  if (airlines.length > 0) {
    filteredFlightsOutbound =
      filteredFlightsOutbound &&
      filteredFlightsOutbound?.filter((flight) =>
        airlines.includes(flight?.Segments[0][0]?.Airline?.AirlineName)
      );

    filteredFlightsInbound =
      filteredFlightsInbound &&
      filteredFlightsInbound?.filter((flight) =>
        airlines.includes(flight?.Segments[0][0]?.Airline?.AirlineName)
      );

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
  }

  if (price.length > 0) {
    filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
      const markup = getMarkup(flight?.markup, flight);
      if (
        Math.ceil(flight?.Fare?.PublishedFare) + markup >=
          Math.ceil(price[0]) &&
        Math.ceil(flight?.Fare?.PublishedFare) + markup <= Math.ceil(price[1])
      ) {
        return true;
      }
    });

    filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
      const markup = getMarkup(flight?.markup, flight);
      if (
        Math.ceil(flight?.Fare?.PublishedFare) + markup >=
          Math.ceil(price[0]) &&
        Math.ceil(flight?.Fare?.PublishedFare) + markup <= Math.ceil(price[1])
      ) {
        return true;
      }
    });

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
  }

  if (duration.length > 0) {
    filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
      let totalDuration = 0;
      flight?.Segments[0]?.map((segment) => {
        totalDuration += segment?.Duration;
      });
      if (totalDuration >= duration[0] && totalDuration <= duration[1])
        return true;
    });

    filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
      let totalDuration = 0;
      flight?.Segments[0]?.map((segment) => {
        totalDuration += segment?.Duration;
      });
      if (totalDuration >= duration[0] && totalDuration <= duration[1])
        return true;
    });

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
  }

  if (refundOption.length > 0) {
    const isRefundable = refundOption.includes(1) || refundOption.includes(3);
    const nonRefundable = refundOption.includes(2);
    if (isRefundable && nonRefundable == false) {
      filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
        if (flight?.NonRefundable == false) {
          return true;
        }
      });

      filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
        if (flight?.NonRefundable == false) {
          return true;
        }
      });

      if (filteredFlightsOutbound?.length > 0)
        isAnyFilteredFlightOutboundFound = true;

      if (filteredFlightsInbound?.length > 0)
        isAnyFilteredFlightInboundFound = true;
    } else if (nonRefundable && isRefundable == false) {
      filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
        if (flight?.NonRefundable == true) {
          return true;
        }
      });

      filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
        if (flight?.NonRefundable == true) {
          return true;
        }
      });

      if (filteredFlightsOutbound?.length > 0)
        isAnyFilteredFlightOutboundFound = true;

      if (filteredFlightsInbound?.length > 0)
        isAnyFilteredFlightInboundFound = true;
    } else {
      isAnyFilteredFlightOutboundFound = true;
      isAnyFilteredFlightInboundFound = true;
    }
  }

  if (outBoundDepartureDuration.length > 0) {
    filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
      const time = flight?.Segments[0][0]?.Origin?.DepTime;
      const departureTime = moment(time);
      const hour = departureTime.hour();
      const timeFrame = handleTimeFrame(hour);
      if (outBoundDepartureDuration.includes(timeFrame)) return true;
    });

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;
    isAnyFilteredFlightInboundFound = true;
  }

  if (outBoundArrivalDuration.length > 0) {
    filteredFlightsOutbound = filteredFlightsOutbound.filter((flight) => {
      const time =
        flight?.Segments[0][flight?.Segments[0].length - 1]?.Destination
          ?.ArrTime;
      const departureTime = moment(time);
      const hour = departureTime.hour();
      const timeFrame = handleTimeFrame(hour);
      if (outBoundArrivalDuration.includes(timeFrame)) return true;
    });

    if (filteredFlightsOutbound?.length > 0)
      isAnyFilteredFlightOutboundFound = true;

    isAnyFilteredFlightInboundFound = true;
  }

  if (inBoundDepartureDuration.length > 0) {
    filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
      const time = flight?.Segments[0][0]?.Origin?.DepTime;
      const departureTime = moment(time);
      const hour = departureTime.hour();
      const timeFrame = handleTimeFrame(hour);
      if (inBoundDepartureDuration.includes(timeFrame)) return true;
    });

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
    isAnyFilteredFlightOutboundFound = true;
  }

  if (inBoundArrivalDuration.length > 0) {
    filteredFlightsInbound = filteredFlightsInbound.filter((flight) => {
      const time =
        flight?.Segments[0][flight?.Segments[0].length - 1]?.Destination
          ?.ArrTime;
      const departureTime = moment(time);
      const hour = departureTime.hour();
      const timeFrame = handleTimeFrame(hour);
      if (inBoundArrivalDuration.includes(timeFrame)) return true;
    });

    if (filteredFlightsInbound?.length > 0)
      isAnyFilteredFlightInboundFound = true;
    isAnyFilteredFlightOutboundFound = true;
  }

  if (isAnyFilteredFlightOutboundFound == false) filteredFlightsOutbound = [];
  if (isAnyFilteredFlightInboundFound == false) filteredFlightsInbound = [];
  return [filteredFlightsOutbound, filteredFlightsInbound];
};

export const sortFlightsOnPrice = (value, flights, rateOfExchange) => {
  const flightsCopy = [...flights];

  flightsCopy.sort((a, b) => {
    const markupA = getMarkup(a[0]?.markup, a[0], rateOfExchange) || 0;
    const markupB = getMarkup(b[0]?.markup, b[0], rateOfExchange) || 0;
    const fareA = a[0].Fare.TotalFare * rateOfExchange + markupA;
    const fareB = b[0].Fare.TotalFare * rateOfExchange + markupB;

    if (value) {
      return fareA - fareB;
    } else {
      return fareB - fareA;
    }
  });

  return flightsCopy;
};

export const sortFlightsOnPriceForDRT = (value, flights) => {
  const flightsCopy = [...flights];

  flightsCopy.sort((a, b) => {
    const markupA = getMarkup(a?.markup, a);
    const markupB = getMarkup(b?.markup, b);
    const fareA = a.Fare.PublishedFare + markupA;
    const fareB = b.Fare.PublishedFare + markupB;

    if (value) {
      return fareA - fareB;
    } else {
      return fareB - fareA;
    }
  });

  return flightsCopy;
};

const handleTimeFrame = (hour) => {
  let timeFrame = 0;
  if (hour < 6) {
    timeFrame = 1;
  } else if (hour < 12) {
    timeFrame = 2;
  } else if (hour < 18) {
    timeFrame = 3;
  } else {
    timeFrame = 4;
  }
  return timeFrame;
};

const getMarkup = (markupDetails, flight, rateOfExchange) => {
  if (!markupDetails || Object.keys(markupDetails).length === 0) {
    return;
  }
  let amt = 0;
  const { amount, amountType, appliedOn, maxAmount } = markupDetails;
  if (amountType === "FLAT") {
    amt = amount < maxAmount ? amount : maxAmount;
  } else {
    amt = amount < maxAmount ? amount : maxAmount;
    if (appliedOn === "BASEFARE") {
      amt =
        (amt *
          (flight?.Fare?.TotalFare * rateOfExchange +
            flight?.Fare?.OtherCharges)) /
        100;
    } else {
      amt = (amt * (flight?.Fare?.TotalFare * rateOfExchange)) / 100;
    }
  }
  amt = maxAmount < amt ? maxAmount : amt;
  return Math.ceil(amt);
};

export const handleDecrypt = async (encryptedData) => {
  try {
    const REACT_APP_SECRETKEY = process.env.REACT_APP_SECRETKEY;
    const key = CryptoJS.enc.Utf8.parse(REACT_APP_SECRETKEY);
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
      mode: CryptoJS.mode.ECB,
    });
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    const decryptedObject = JSON.parse(decryptedText);
    return decryptedObject;
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};
