import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import DashedSvg from "../../../../../../../assets/images/flight-result-dashed.svg";
import PlanelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import InfoSvg from "../../../../../../../assets/images/info-circle.svg";
import DividerSvg from "../../../../../../../assets/images/flight-result-card-divider.svg";
import FlightDetails from "../../../cards/flight-details";
import Tippy from "@tippy.js/react";
import CheckSvg from "../../../../../../../assets/images/baggage-check.svg";
import "./style.css";
import CheckPrice from "../check-price";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import {
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../utils/helpers";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlightMultiCityTripCard = ({
  flight,
  handleBookNow,
  index,
  selected,
}) => {
  if (Object.keys(flight).length <= 0) return;
  const classes = useStyles();
  const { t } = useTranslation();
  const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
  const [checkPriceDetailsModal, setCheckPriceDetailsModal] = useState(false);
  const [markup, setMarkup] = useState(0);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const { flightFrom, flightTo } = useSelector(
    (state) => state.modifySearchFlight
  );

  let {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
  } = flight[0];
  BaseFare = Math.ceil(TotalFare) - Math.ceil(Tax) - Math.ceil(VATAmount);
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    CabinBaggage,
    Baggage,
    Destination: { ArrTime: layoverDepTime },
    Duration: departureDuration,
  } = Segments[index][0];
  const {
    Duration: arrivalDuration,
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[index][Segments[index]?.length - 1];
  const length = Segments[index]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  useEffect(() => {
    getMarkup(flight[0]?.markup);
  }, [TotalFare, BaseFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (BaseFare + OtherCharges * rateOfExchange)) / 100;
      } else {
        amt = (amt * (TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          <div className="book-flight-card-container-content1-mct">
            <div className="book-flight-card-icon-mct">
              <img
                src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
                className="book-flight-card-image-mct"
                style={{ height: "36px", width: "42px" }}
              />
            </div>
            <div className="book-flight-card-flight-details-mct">
              <div>
                <div className="book-flight-card-flight-name-mct">
                  {AirlineName}
                </div>
                <div className="book-flight-card-flight-code-mct">
                  {AirlineCode}-{FlightNumber}
                </div>
              </div>
            </div>
            <div className="book-flight-card-place-details-mct">
              <div>
                <div className="book-flight-card-place-time-mct">
                  {DepTime && timeString(DepTime)}
                </div>
                <div className="book-flight-card-place-name-mct">{origin}</div>
              </div>
            </div>
            <div className="book-flight-card-time-details-mct">
              <div className="book-flight-card-time-taken-mct">
                <ReactSVG src={ClockSvg} />
                {/* {firstAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                  : lastAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                  : flightDurationInHoursAndMinutes(departureDuration)}
                {addDurationStrings(totalFlightDuration, layoverTime)} */}
                {convertAccumulatedTime(firstAccumulatedDuration)}
              </div>
              <div className="book-flight-card-way-mct">
                <ReactSVG src={DashedSvg} />
                <div className="book-flight-card-way-text-mct">
                  {flightTravelType}
                </div>
                <ReactSVG src={DashedSvg} />
                <ReactSVG
                  src={PlanelineSvg}
                  className="book-flight-card-plane-line-icon-mct"
                />
              </div>
              <Tippy
                content={
                  <div className={classes.baggagesMainContainer}>
                    <p className={classes.baggageAllowance}>
                      {t("flightSearch.baggageAllowance")}
                    </p>
                    <div className={classes.baggageContainer}>
                      <div className={classes.checkContainer}>
                        <ReactSVG src={CheckSvg} />
                      </div>
                      <p className={classes.baggageAllowed}>
                        {Baggage} {t("flightSearch.baggageAllowed")}
                      </p>
                    </div>
                    {CabinBaggage && (
                      <div className={classes.cabinBaggageContainer}>
                        <div className={classes.checkSvgContainer}>
                          <ReactSVG src={CheckSvg} />
                        </div>
                        <p className={classes.cabinBaggageAllowed}>
                          {CabinBaggage} {t("flightSearch.cabinBaggageAllowed")}
                        </p>
                      </div>
                    )}
                  </div>
                }
                arrow={true}
                placement="bottom-end"
                animation="shift-away"
              >
                <div className="book-flight-card-baggage-options-mct">
                  <ReactSVG src={BaggageSvg} />
                  {t("flightSearch.baggageOptionsAvailable")}
                </div>
              </Tippy>
            </div>
            <div className="book-flight-card-place-details-mct">
              <div>
                <div className="book-flight-card-place-time-mct">
                  {ArrTime && timeString(ArrTime)}
                </div>
                <div className="book-flight-card-place-name-mct">
                  {destination}
                </div>
              </div>
            </div>
            <div
              className="book-flight-card-flight-details-button-mct"
              onClick={() => setShowFlightDetailsModal(true)}
            >
              {t("flightSearch.flightDetails")}
            </div>
          </div>
          <ReactSVG src={DividerSvg} />
          <div className="book-flight-card-container-content2-mct">
            {(index === 2 ||
              (index < 2 && Object.keys(flightFrom[index + 1]).length === 0) ||
              Object.keys(flightTo[index + 1]).length === 0) && (
              <div className="book-flight-card-price-details-mct">
                <Tippy
                  content={
                    <div className={classes.fareDetailsMainContainer}>
                      <p className={classes.fareDetails}>
                        {" "}
                        {t("flightSearch.fareDetails")}
                      </p>
                      <div className={classes.fareBreakDownContainer}>
                        <div className={classes.baseFareContainer}>
                          <p className={classes.baseFareText}>
                            {" "}
                            {t("flightSearch.baseFare")}
                          </p>
                          <p className={classes.baseFare}>
                            {t("flightSearch.currencySymbol")}{" "}
                            {(
                              Math.ceil(TotalFare * rateOfExchange) -
                              (Math.ceil(Tax * rateOfExchange) +
                                Math.ceil(VATAmount * rateOfExchange)) +
                              markup
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                        <div className={classes.taxesAndFeeContainer}>
                          <p className={classes.taxesAndFeesText}>
                            {t("flightSearch.taxesAndFees")}{" "}
                          </p>
                          <p className={classes.taxesAndFees}>
                            {t("flightSearch.currencySymbol")}{" "}
                            {Math.ceil(Tax * rateOfExchange).toLocaleString(
                              "en-IN"
                            )}
                          </p>
                        </div>
                        <div className={classes.taxesAndFeeContainer}>
                          <p className={classes.taxesAndFeesText}>
                            {t("flightSearch.VAT")}{" "}
                            {/* VAT */}
                          </p>
                          <p className={classes.taxesAndFees}>
                            {t("flightSearch.currencySymbol")}{" "}
                            {Math.ceil(
                              VATAmount * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  arrow={true}
                  placement="bottom-end"
                  animation="shift-away"
                >
                  <div className="book-flight-card-price-info-mct">
                    {t("flightSearch.totalIncludingTax")}{" "}
                    <ReactSVG
                      src={InfoSvg}
                      className="book-flight-card-info-icon-mct"
                    />
                  </div>
                </Tippy>
                <div className="book-flight-card-price-mct">
                  SAR{" "}
                  {(
                    Math.ceil(TotalFare * rateOfExchange) + markup
                  ).toLocaleString("en-IN")}
                </div>
              </div>
            )}
            {flightFrom[index + 1]?.code && flightTo[index + 1]?.code && (
              <div
                className="book-flight-card-button-mct"
                onClick={() => handleBookNow(selected)}
              >
                {t("flightSearch.bookNow")}
              </div>
            )}
            {index === 2 ||
            (index < 2 && Object.keys(flightFrom[index + 1]).length === 0) ||
            Object.keys(flightTo[index + 1]).length === 0 ? (
              flight?.length > 1 ? (
                <div
                  className={classes.checkPriceContainer}
                  style={{
                    backgroundColor: checkPriceDetailsModal
                      ? "#FFF"
                      : "#1b1d52",
                  }}
                  onClick={() => setCheckPriceDetailsModal((prev) => !prev)}
                >
                  <p
                    className={classes.checkPrice}
                    style={{
                      color: checkPriceDetailsModal ? "#344054" : "#FFF",
                    }}
                  >
                    {t("flightSearch.checkPrice")}
                  </p>
                  {checkPriceDetailsModal ? (
                    <ExpandLessIcon sx={{ color: "#1b1d52" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#FFF" }} />
                  )}
                </div>
              ) : (
                <div
                  className="book-flight-card-button-mct"
                  onClick={() => handleBookNow(selected, 0)}
                >
                  {t("flightSearch.bookNow")}
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        {(index === 2 ||
          (index < 2 && Object.keys(flightFrom[index + 1]).length === 0) ||
          Object.keys(flightTo[index + 1]).length === 0) &&
          checkPriceDetailsModal && (
            <CheckPrice
              flights={flight}
              index={1}
              handleBookNow={handleBookNow}
              selected={selected}
            />
          )}
        {showFlightDetailsModal && (
          <FlightDetails
            markup={markup}
            setShowFlightDetailsModal={setShowFlightDetailsModal}
            showFlightDetailsModal={showFlightDetailsModal}
            flight={flight[0]}
            timeString={timeString}
            segmentIndex={index}
          />
        )}
      </div>
    </>
  );
};

export default BookFlightMultiCityTripCard;

BookFlightMultiCityTripCard.propTypes = {
  flight: PropTypes.any,
  handleBookNow: PropTypes.func,
  index: PropTypes.any,
  selected: PropTypes.any,
};
