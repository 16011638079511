import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

export default function Payment() {
  const { checkoutId } = useParams();
  const location = useLocation()
  const flightCouponId = location.state.flightCouponId
  const flightIsCouponApplied = location.state.flightIsCouponApplied
  const hotelCouponId = location.state.hotelCouponId
  console.log('location ', location)
  const [loading, setLoading] = useState(true);
  //   const location = useLocation();
  //   console.log("location123", location.state);
  //   const bookingId = useSelector((state) => state.bookingStatus.bookingId);
  //   console.log("bookingId", bookingId);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;
    script.onload = () => {
      setLoading(false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [checkoutId]);

  useEffect(() => {

  }, [])

  return (
    <div>
      {loading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "14px",
            }}
          >
            <Rings height={120} width={120} color="#1B1D52" />
          </div>
        </div>
      ) : (
        <form
          action={`/payment-processing/${checkoutId}/?ci=${flightCouponId}&isapplied=${flightIsCouponApplied}&hci=${hotelCouponId}`}
          className="paymentWidgets"
          data-brands="MADA AMEX MASTER VISA"
          method="get"
        ></form>
      )}
    </div>
  );
}
