import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { Box } from "@mui/material";
import moment from "moment";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import PropTypes from "prop-types";
import planelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import {
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../utils/helpers";
import eventBus from "../../../../../../../core/eventBus";
import FlightDetailsMobile from "../../../cards/flight-details-mobile";
import CheckPrice from "../check-price";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlightInternationalRoundTripCardMobile = ({
  flight,
  handleBookNow,
  index,
  selected,
}) => {
  const style = useStyles();
  const [markup, setMarkup] = useState(0);
  const { t } = useTranslation();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges },
  } = flight[0];

  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    CabinBaggage,
    Baggage,
    Duration: departureDuration,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[index][0];

  const {
    Duration: arrivalDuration,
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[index][Segments[index]?.length - 1];
  const length = Segments[index]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );
  const handleFlightDetailsBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <FlightDetailsMobile
        flight={flight[0]}
        flightDuration={flightDuration}
        handleBookNow={() => {
          handleBookNow(selected);
        }}
        segmentIndex={index}
        checkPriceRoundWay={true}
        timeString={timeString}
        markup={markup}
      />
    );
  };

  const handleCheckPriceBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <CheckPrice flights={flight} index={1} handleBookNow={handleBookNow} />
    );
  };

  useEffect(() => {
    getMarkup(flight[0]?.markup);
  }, [BaseFare, TotalFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }

    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (BaseFare * rateOfExchange)) / 100;
      } else {
        amt = (amt * (TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  return (
    <Box className={style.bookFlightMobile}>
      <Box className={style.flightDetailsCard}>
        <Box className={style.airLinesDetails}>
          <img
            src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
            style={{ height: "36px", width: "42px" }}
          ></img>
          <div></div>
          <Box className={style.airlineNameLayout}>
            <div className={style.airLinesName}>{AirlineName}</div>
            <div className={style.airLineCode}>
              {AirlineCode}-{FlightNumber}
            </div>
          </Box>
        </Box>
        <Box className={style.container}>
          <Box className={style.flightFromDetails}>
            <div className={style.departureDate}>
              {DepTime && timeString(DepTime)}
            </div>
            <div className={style.flightFrom}>{origin}</div>
          </Box>
          <Box className={style.flightTime}>
            <Box className={style.flightDuration}>
              <ReactSVG
                src={ClockSvg}
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
              <div className={style.durationTime}>
                {/* {firstAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                  : lastAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                  : flightDurationInHoursAndMinutes(departureDuration)} */}
                {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
                {convertAccumulatedTime(lastAccumulatedDuration)}
              </div>
            </Box>
            <Box className={style.flightTravelType}>
              <div
                style={{
                  width: "25px",
                  height: "1px",
                  background: "#D0D5DD",
                }}
              ></div>
              <div className={style.flightTravelTypeText}>
                {flightTravelType}
              </div>
              <div
                style={{
                  width: "20px",
                  height: "1px",
                  background: "#D0D5DD",
                }}
              ></div>
              <ReactSVG src={planelineSvg} />
            </Box>
          </Box>
          <Box className={style.flightToDetails}>
            <div className={style.departureDate}>
              {ArrTime && timeString(ArrTime)}
            </div>
            <div className={style.flightFrom}>{destination}</div>
          </Box>
        </Box>
      </Box>
      <Box className={style.fairDetailscard}>
        <Box className={style.baggageOption}>
          <Box
            className={style.baggageOptionLayout}
            onClick={() => handleFlightDetailsBottomSheet()}
          >
            <div className={style.baggageText}> Flight Details </div>
          </Box>
        </Box>
        {index === 1 && (
          <Box className={style.fairTextLayout}>
            <div className={style.fairText}>
              {`$${(
                Math.ceil(TotalFare * rateOfExchange) + markup
              ).toLocaleString("en-IN")}`}
            </div>
          </Box>
        )}
      </Box>
      {index === 0 && (
        <button
          className={style.buttonLayout}
          onClick={() => handleBookNow(selected)}
        >
          Book Now
        </button>
      )}
      {index === 1 ? (
        flight?.length > 1 ? (
          <button
            className={style.buttonLayout}
            onClick={handleCheckPriceBottomSheet}
          >
            Check Price
          </button>
        ) : (
          <button
            className={style.buttonLayout}
            onClick={() => handleBookNow(flight[selected])}
          >
            Book Now
          </button>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BookFlightInternationalRoundTripCardMobile;

BookFlightInternationalRoundTripCardMobile.propTypes = {
  flight: PropTypes.any,
  handleBookNow: PropTypes.func,
  index: PropTypes.any,
  selected: PropTypes.any,
};
