import React, { useState } from "react";
import useStyles from "./style";
import { Box } from "@mui/material";
import moment from "moment";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import PropTypes from "prop-types";
import planelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import FlightDetailsMobile from "../../../cards/flight-details-mobile";
import eventBus from "../../../../../../../core/eventBus";
import {
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../utils/helpers";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlightMobile = ({ flight, handleBookNow }) => {
  const style = useStyles();
  const { t } = useTranslation();
  const {
    Segments,
    Fare: { PublishedFare, BaseFare, Tax },
  } = flight;
  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };
  const length = Segments[0]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const flightDuration = (departureDateTime, arrivalDateTime) => {
    const departureMoment = moment(departureDateTime);
    const arrivalMoment = moment(arrivalDateTime);
    const duration = moment.duration(arrivalMoment.diff(departureMoment));
    const hoursDifference = duration.hours();
    const minutesDifference = duration.minutes();

    if (minutesDifference > 0 && hoursDifference > 0)
      return `${hoursDifference}h ${minutesDifference}m`;
    if (hoursDifference <= 0) return `${minutesDifference}m`;
    return `${hoursDifference}h`;
  };
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    Duration: departureDuration,
    Destination: { ArrTime: layoverDepTime },
    CabinBaggage,
    Baggage,
  } = Segments[0][0];
  const {
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Duration: arrivalDuration,
    AccumulatedDuration,
    Origin: { DepTime: layoverArrTime },
  } = Segments[0][Segments[0]?.length - 1];

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  return (
    <Box className={style.bookFlightMobile}>
      <Box className={style.flightDetailsCard}>
        <Box className={style.airLinesDetails}>
          <img
            src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
            style={{ height: "36px", width: "42px" }}
          ></img>
          <div></div>
          <Box className={style.airlineNameLayout}>
            <div className={style.airLinesName}>{AirlineName}</div>
            <div className={style.airLineCode}>
              {AirlineCode}-{FlightNumber}
            </div>
          </Box>
        </Box>
        <Box className={style.flightFromDetails}>
          <div className={style.departureDate}>
            {DepTime && timeString(DepTime)}
          </div>
          <div className={style.flightFrom}>{origin}</div>
        </Box>
        <Box className={style.flightTime}>
          <Box className={style.flightDuration}>
            <ReactSVG
              src={ClockSvg}
              style={{
                width: "12px",
                height: "12px",
              }}
            />
            <div className={style.durationTime}>
              {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
              {convertAccumulatedTime(AccumulatedDuration)}
            </div>
          </Box>
          <Box className={style.flightTravelType}>
            <div
              style={{
                width: "20px",
                height: "1px",
                background: "#D0D5DD",
              }}
            ></div>
            <div className={style.flightTravelTypeText}>{flightTravelType}</div>
            <div
              style={{
                width: "20px",
                height: "1px",
                background: "#D0D5DD",
              }}
            ></div>
            <ReactSVG src={planelineSvg} />
          </Box>
        </Box>
        <Box className={style.flightToDetails}>
          <div className={style.departureDate}>
            {ArrTime && timeString(ArrTime)}
          </div>
          <div className={style.flightFrom}>{destination}</div>
        </Box>
      </Box>
    </Box>
  );
};

export default BookFlightMobile;
BookFlightMobile.propTypes = {
  flight: PropTypes.object,
  handleBookNow: PropTypes.func,
};
