import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import { ReactSVG } from "react-svg";
import FlightDetails from "../../../cards/flight-details";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import DashedSvg from "../../../../../../../assets/images/flight-result-dashed.svg";
import PlanelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import InfoSvg from "../../../../../../../assets/images/info-circle.svg";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import CheckSvg from "../../../../../../../assets/images/baggage-check.svg";
import DividerSvg from "../../../../../../../assets/images/flight-result-card-divider.svg";
import CheckPrice from "../check-price";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import {
  TimeFormat,
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../utils/helpers";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setSarValue,
  setUsdValue,
} from "../../../../../../../utils/slices/rateOfExchangeSlice";

const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlight = ({ flight, handleBookNow, index }) => {
  const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
  const [checkPriceDetailsModal, setCheckPriceDetailsModal] = useState(false);
  const [markup, setMarkup] = useState(0);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  const classes = useStyles();
  const dispatch = useDispatch();
  // const price = useSelector((state) => state.rateOfExchange.usdValue);
  const { t } = useTranslation();

  let {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
  } = flight[0];

  useEffect(() => {
    dispatch(setUsdValue({ flightFare: flight[0].Fare, index: index }));
  }, [flight]);

  let BaseFareSAR = (TotalFare - (Tax + VATAmount)) * rateOfExchange;
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    CabinBaggage,
    IncludedBaggage,
    Duration: departureDuration,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[0][0];
  const {
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Duration: arrivalDuration,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[0][Segments[0]?.length - 1];
  const length = Segments[0]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };

  useEffect(() => {
    getMarkup(flight[0]?.markup);
  }, [BaseFare, TotalFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * BaseFareSAR) / 100;
      } else {
        amt = (amt * (TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          <div className="book-flight-card-container-content1">
            <div className="book-flight-card-icon-one-way">
              <img
                src={`${S3Bucket}/AIRLINE_ICON/${AirlineCode}.png`}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
                className="book-flight-card-image-one-way"
                style={{ height: "36px", width: "42px" }}
              />
            </div>
            <div className="book-flight-card-flight-details-one-way">
              <div>
                <div className="book-flight-card-flight-name-one-way">
                  {AirlineName}
                </div>
                <div className="book-flight-card-flight-code-one-way">
                  {AirlineCode}-{FlightNumber}
                </div>
              </div>
            </div>
            <div className="book-flight-card-place-details-one-way">
              <div>
                <div className="book-flight-card-place-time-one-way">
                  {DepTime && timeString(DepTime)}
                </div>
                <div className="book-flight-card-place-name-one-way">
                  {origin}
                </div>
              </div>
            </div>
            <div className="book-flight-card-time-details-one-way">
              <div className="book-flight-card-time-taken-one-way">
                <ReactSVG src={ClockSvg} />
                {
                  firstAccumulatedDuration > 0
                    ? firstAccumulatedDuration
                    : lastAccumulatedDuration > 0
                    ? lastAccumulatedDuration
                    : convertAccumulatedTime(lastAccumulatedDuration)

                  // TimeFormat(departureDuration)
                }
                {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
              </div>
              <div className="book-flight-card-way-one-way">
                <ReactSVG src={DashedSvg} />
                <div className="book-flight-card-way-text-one-way">
                  {flightTravelType}
                </div>
                <ReactSVG src={DashedSvg} />
                <ReactSVG
                  src={PlanelineSvg}
                  className="book-flight-card-plane-line-icon-one-way"
                />
              </div>
              <Tippy
                content={
                  <div className={classes.baggagesContainer}>
                    <p className={classes.baggageAllowance}>
                      {t("flightSearch.baggageAllowance")}
                    </p>
                    <div className={classes.baggageAllowedContainer}>
                      <div className={classes.checkContainer}>
                        <ReactSVG src={CheckSvg} />
                      </div>
                      <p className={classes.baggageAllowed}>
                        {IncludedBaggage} {t("flightSearch.baggageAllowed")}
                      </p>
                    </div>
                    {CabinBaggage && (
                      <div className={classes.cabinBaggageMainContainer}>
                        <div className={classes.checkContainer2}>
                          <ReactSVG src={CheckSvg} />
                        </div>
                        <p className={classes.cabinBaggageAllowed}>
                          {CabinBaggage} {t("flightSearch.cabinBaggageAllowed")}
                        </p>
                      </div>
                    )}
                  </div>
                }
                arrow={true}
                placement="bottom-end"
                animation="shift-away"
              >
                <div className="book-flight-card-baggage-options-one-way">
                  <ReactSVG src={BaggageSvg} />
                  {t("flightSearch.baggageOptionsAvailable")}
                </div>
              </Tippy>
            </div>
            <div className="book-flight-card-place-details-one-way">
              <div>
                <div className="book-flight-card-place-time-one-way">
                  {ArrTime && timeString(ArrTime)}
                </div>
                <div className="book-flight-card-place-name-one-way">
                  {destination}
                </div>
              </div>
            </div>
            <div
              className="book-flight-card-flight-details-button-one-way"
              onClick={() => setShowFlightDetailsModal(true)}
            >
              {t("flightSearch.flightDetails")}
            </div>
          </div>
          <ReactSVG src={DividerSvg} />
          <div className="book-flight-card-container-content2">
            <div className="book-flight-card-price-details-one-way">
              <Tippy
                content={
                  <div className={classes.faresMainContainer}>
                    <p className={classes.fairDetailsText}>
                      {t("flightSearch.flightDetails")}
                    </p>
                    <div className={classes.fareDetailsContainer}>
                      <div className={classes.baseFareContainer}>
                        <p className={classes.baseFareText}>
                          {t("flightSearch.baseFare")}
                        </p>
                        <p className={classes.baseFare}>
                          {""}
                          {t("flightSearch.currencySymbol")}{" "}
                          {(
                            Math.ceil(
                              Math.ceil(TotalFare * rateOfExchange) -
                                (Math.ceil(Tax * rateOfExchange) +
                                  Math.ceil(VATAmount * rateOfExchange))
                            ) + Math.ceil(markup)
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                      <div className={classes.taxesAndFeeContainer}>
                        <p className={classes.taxesAndFeesText}>
                          {t("flightSearch.taxesAndFees")}
                        </p>
                        <p className={classes.taxesAndFees}>
                          {t("flightSearch.currencySymbol")}{" "}
                          {Math.ceil(Tax * rateOfExchange).toLocaleString(
                            "en-IN"
                          )}
                        </p>
                      </div>
                      <div className={classes.vatContainer}>
                        <p className={classes.taxesAndFeesText}>
                          {t("flightSearch.VAT")}
                          {/* VAT */}
                        </p>
                        <p className={classes.taxesAndFees}>
                          {t("flightSearch.currencySymbol")}{" "}
                          {Math.ceil(VATAmount * rateOfExchange).toLocaleString(
                            "en-IN"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                }
                arrow={true}
                placement="bottom-end"
                animation="shift-away"
              >
                <div className="book-flight-card-price-info-one-way">
                  {t("flightSearch.totalIncludingTax")}
                  <ReactSVG
                    src={InfoSvg}
                    className="book-flight-card-info-icon-one-way"
                  />
                </div>
              </Tippy>
              <div className="book-flight-card-price-one-way">
                {t("flightSearch.currencySymbol")} {""}
                {/* {(Math.ceil(PublishedFare) + Math.ceil(markup)).toLocaleString(
                  "en-IN"
                )} */}
                {(
                  Math.ceil(TotalFare * rateOfExchange) + Math.ceil(markup)
                ).toLocaleString("en-IN")}
              </div>
            </div>
            {flight?.length > 1 ? (
              <div
                className={classes.checkPriceContainer}
                style={{
                  backgroundColor: checkPriceDetailsModal ? "#FFF" : "#1b1d52",
                }}
                onClick={() => setCheckPriceDetailsModal((prev) => !prev)}
              >
                <p
                  className={classes.checkPrice}
                  style={{
                    color: checkPriceDetailsModal ? "#344054" : "#FFF",
                  }}
                >
                  {t("flightSearch.checkPrice")}
                </p>
                {checkPriceDetailsModal ? (
                  <ExpandLessIcon sx={{ color: "#1b1d52" }} />
                ) : (
                  <ExpandMoreIcon sx={{ color: "#FFF" }} />
                )}
              </div>
            ) : (
              <div
                className="book-flight-card-button-mct"
                onClick={() => handleBookNow(flight[0], flight[0]?.markup)}
              >
                {t("flightSearch.bookNow")}
              </div>
            )}
          </div>
        </div>
        {checkPriceDetailsModal && (
          <CheckPrice
            flights={flight}
            handleBookNow={handleBookNow}
            markupDetails={flight[0].markup}
          />
        )}
        {showFlightDetailsModal && (
          <FlightDetails
            markup={markup}
            setShowFlightDetailsModal={setShowFlightDetailsModal}
            showFlightDetailsModal={showFlightDetailsModal}
            flight={flight[0]}
            flightDuration={flightDuration}
            timeString={timeString}
          />
        )}
      </div>
    </>
  );
};

export default BookFlight;

BookFlight.propTypes = {
  flight: PropTypes.any,
  handleBookNow: PropTypes.func,
};
